import { systemWeights } from 'react-native-typography';
export default {
  disabledOpacity: 0.5,
  roundness: 6,
  colors: {
    Background: 'rgba(251, 252, 253, 1)',
    'Custom Color': 'rgb(154, 189, 220)',
    'Custom Color_2': 'rgb(255, 255, 255)',
    'Custom Color_3': 'rgb(255, 223, 158)',
    'Custom Color_4': 'rgb(135, 206, 235)',
    'Custom Color_5': 'rgb(73, 151, 161)',
    'Custom Color_6': 'rgb(128, 0, 128)',
    'Custom Color_7': 'rgb(0, 0, 255)',
    'Custom Color_8': 'rgb(255, 0, 0)',
    Divider: 'rgba(234, 237, 242, 1)',
    Error: 'rgba(255, 69, 100, 1)',
    Light: 'rgba(165, 173, 183, 1)',
    'Light Inverse': 'rgba(255, 255, 255, 0.68)',
    Medium: 'rgba(70, 78, 88, 1)',
    'Medium Inverse': 'rgba(255, 255, 255, 0.87)',
    Primary: 'rgb(0, 140, 186)',
    Secondary: 'rgba(59, 201, 234, 1)',
    Strong: 'rgba(18, 20, 44, 1)',
    'Strong Inverse': 'rgba(255, 255, 255, 1)',
    Surface: 'rgba(255, 255, 255, 1)',
    Theme_Color: 'rgba(255, 183, 1, 0.48)',
    Theme_Strong: 'rgb(255, 183, 1)',
    background: 'rgba(251, 252, 253, 1)',
    custom_rgb0_0_255: 'rgb(0, 0, 255)',
    custom_rgb128_0_128: 'rgb(128, 0, 128)',
    custom_rgb135_206_235: 'rgb(135, 206, 235)',
    custom_rgb154_189_220: 'rgb(154, 189, 220)',
    custom_rgb255_0_0: 'rgb(255, 0, 0)',
    custom_rgb255_223_158: 'rgb(255, 223, 158)',
    custom_rgb255_255_255: 'rgb(255, 255, 255)',
    custom_rgb73_151_161: 'rgb(73, 151, 161)',
    divider: 'rgba(234, 237, 242, 1)',
    error: 'rgba(255, 69, 100, 1)',
    light: 'rgba(165, 173, 183, 1)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    medium: 'rgba(70, 78, 88, 1)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    primary: 'rgb(0, 140, 186)',
    secondary: 'rgba(59, 201, 234, 1)',
    strong: 'rgba(18, 20, 44, 1)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    surface: 'rgba(255, 255, 255, 1)',
    themeColor: 'rgba(255, 183, 1, 0.48)',
    themeStrong: 'rgb(255, 183, 1)',
  },
  typography: {
    body1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    body2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
    button: {
      ...systemWeights.bold,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 16,
    },
    caption: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: 16,
    },
    headline1: {
      ...systemWeights.bold,
      fontSize: 60,
      letterSpacing: 0,
      lineHeight: 71,
    },
    headline2: {
      ...systemWeights.bold,
      fontSize: 48,
      letterSpacing: 0,
      lineHeight: 58,
    },
    headline3: {
      ...systemWeights.bold,
      fontSize: 34,
      letterSpacing: 0,
      lineHeight: 40,
    },
    headline4: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: 34,
    },
    headline5: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 26,
    },
    headline6: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 24,
    },
    overline: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 2,
      lineHeight: 16,
    },
    subtitle1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    subtitle2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
  },
};
