import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PhotoReadyXanoApi from '../apis/PhotoReadyXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Components from '../custom-files/Components';
import * as CustomCode from '../custom-files/CustomCode';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Icon,
  Pressable,
  ScreenContainer,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Platform,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const UsersScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [isLoading, setIsLoading] = React.useState(false);
  const exportProfiles = async Variables => {
    if (Platform.OS == 'web') {
      setIsLoading(true);
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', Constants.AUTH_TOKEN);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };

      try {
        const rawRes = await fetch(
          'https://xy5t-co72-skd0.n7c.xano.io/api:qSkZMW3n/profiles-csv',
          requestOptions
        );

        const csvData = await rawRes.text();
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8,' });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        URL.revokeObjectURL(blobUrl);
        setIsLoading(false);
      } catch (e) {
        console.log('error in fetch data', e.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
            backgroundColor: {
              minWidth: Breakpoints.Tablet,
              value: theme.colors['Theme_Color'],
            },
            flexDirection: { minWidth: Breakpoints.Tablet, value: 'row' },
            justifyContent: {
              minWidth: Breakpoints.Tablet,
              value: 'space-between',
            },
          },
          dimensions.width
        )}
      >
        {/* CustomHeader */}
        <Utils.CustomCodeErrorBoundary>
          <Components.MyHeader navigation={props.navigation} />
        </Utils.CustomCodeErrorBoundary>
      </View>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            flex: { minWidth: Breakpoints.Tablet, value: 1 },
            flexDirection: { minWidth: Breakpoints.Tablet, value: 'row' },
          },
          dimensions.width
        )}
      >
        {/* Menu */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: theme.colors['Theme_Color'],
              },
              width: { minWidth: Breakpoints.Tablet, value: '18%' },
            },
            dimensions.width
          )}
        >
          {/* Dashboard */}
          <Surface
            elevation={0}
            {...GlobalStyles.SurfaceStyles(theme)['Menu Surface'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.SurfaceStyles(theme)['Menu Surface'].style,
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('DashboardScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              activeOpacity={0.3}
              style={StyleSheet.applyWidth(
                { height: { minWidth: Breakpoints.Tablet, value: '100%' } },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Tablet,
                      value: 'row',
                    },
                    height: { minWidth: Breakpoints.Tablet, value: '100%' },
                    padding: { minWidth: Breakpoints.Tablet, value: 8 },
                    paddingLeft: { minWidth: Breakpoints.Tablet, value: 15 },
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialCommunityIcons/tablet-dashboard'}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Tablet,
                            value: 'Poppins_400Regular',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'BaiJamjuree_500Medium',
                          },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Dashboard'}
                </Text>
              </View>
            </Pressable>
          </Surface>
          {/* Orders */}
          <Surface
            elevation={0}
            {...GlobalStyles.SurfaceStyles(theme)['Menu Surface'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.SurfaceStyles(theme)['Menu Surface'].style,
                { marginTop: { minWidth: Breakpoints.Tablet, value: 1 } }
              ),
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('OrdersScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              activeOpacity={0.3}
              style={StyleSheet.applyWidth(
                { height: { minWidth: Breakpoints.Tablet, value: '100%' } },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Tablet,
                      value: 'row',
                    },
                    height: { minWidth: Breakpoints.Tablet, value: '100%' },
                    padding: { minWidth: Breakpoints.Tablet, value: 8 },
                    paddingLeft: { minWidth: Breakpoints.Tablet, value: 15 },
                  },
                  dimensions.width
                )}
              >
                <Icon size={24} name={'Entypo/list'} />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Tablet,
                            value: 'Poppins_400Regular',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'BaiJamjuree_500Medium',
                          },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Orders'}
                </Text>
              </View>
            </Pressable>
          </Surface>
          {/* Users */}
          <Surface
            elevation={0}
            {...GlobalStyles.SurfaceStyles(theme)['Menu Surface'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.SurfaceStyles(theme)['Menu Surface'].style,
                { marginTop: { minWidth: Breakpoints.Tablet, value: 1 } }
              ),
              dimensions.width
            )}
          >
            <Pressable
              activeOpacity={0.3}
              style={StyleSheet.applyWidth(
                { height: { minWidth: Breakpoints.Tablet, value: '100%' } },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    backgroundColor: {
                      minWidth: Breakpoints.Tablet,
                      value: theme.colors['Custom Color_2'],
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Tablet,
                      value: 'row',
                    },
                    height: { minWidth: Breakpoints.Tablet, value: '100%' },
                    padding: { minWidth: Breakpoints.Tablet, value: 8 },
                    paddingLeft: { minWidth: Breakpoints.Tablet, value: 15 },
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={theme.colors['Theme_Strong']}
                  name={'Feather/users'}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Tablet,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Theme_Strong'],
                          },
                        ],
                        fontFamily: {
                          minWidth: Breakpoints.Laptop,
                          value: 'BaiJamjuree_600SemiBold',
                        },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Users'}
                </Text>
              </View>
            </Pressable>
          </Surface>
          {/* Menu Bottom View */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: { minWidth: Breakpoints.Tablet, value: 1 },
                justifyContent: {
                  minWidth: Breakpoints.Tablet,
                  value: 'flex-end',
                },
                paddingBottom: { minWidth: Breakpoints.Tablet, value: 10 },
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Tablet,
                          value: 'Poppins_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'BaiJamjuree_400Regular',
                        },
                      ],
                      fontSize: { minWidth: Breakpoints.Tablet, value: 11 },
                      lineHeight: { minWidth: Breakpoints.Tablet, value: 14 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Powered by:'}
              </Text>
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={Images.DraftbitLogo}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: { minWidth: Breakpoints.Tablet, value: 35 } }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
        </View>
        {/* Content */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Tablet, value: 'stretch' },
              flex: { minWidth: Breakpoints.Tablet, value: 1 },
              flexDirection: { minWidth: Breakpoints.Tablet, value: 'column' },
              flexWrap: { minWidth: Breakpoints.Tablet, value: 'wrap' },
            },
            dimensions.width
          )}
        >
          {/* Labels */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
                backgroundColor: {
                  minWidth: Breakpoints.Tablet,
                  value: theme.colors['Divider'],
                },
                flexDirection: { minWidth: Breakpoints.Tablet, value: 'row' },
                height: { minWidth: Breakpoints.Tablet, value: 50 },
                justifyContent: [
                  { minWidth: Breakpoints.Tablet, value: 'flex-start' },
                  { minWidth: Breakpoints.Desktop, value: 'space-between' },
                  { minWidth: Breakpoints.Laptop, value: 'space-between' },
                ],
                paddingLeft: { minWidth: Breakpoints.Tablet, value: '2%' },
                paddingRight: { minWidth: Breakpoints.Desktop, value: '2%' },
              },
              dimensions.width
            )}
          >
            {/* ID */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.Desktop, value: 'center' },
                    { minWidth: Breakpoints.Laptop, value: 'center' },
                  ],
                  justifyContent: {
                    minWidth: Breakpoints.Tablet,
                    value: 'center',
                  },
                  width: [
                    { minWidth: Breakpoints.Tablet, value: '5%' },
                    { minWidth: Breakpoints.Desktop, value: 75 },
                    { minWidth: Breakpoints.Laptop, value: 75 },
                  ],
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['List_Labels'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['List_Labels'].style,
                    {
                      fontFamily: {
                        minWidth: Breakpoints.Laptop,
                        value: 'BaiJamjuree_500Medium',
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'User ID'}
              </Text>
            </View>
            {/* Name */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: {
                    minWidth: Breakpoints.Tablet,
                    value: 'flex-start',
                  },
                  width: [
                    { minWidth: Breakpoints.Tablet, value: '20%' },
                    { minWidth: Breakpoints.Desktop, value: 120 },
                  ],
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['List_Labels'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['List_Labels'].style,
                    {
                      fontFamily: {
                        minWidth: Breakpoints.Laptop,
                        value: 'BaiJamjuree_500Medium',
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Name'}
              </Text>
            </View>
            {/* Email */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: {
                    minWidth: Breakpoints.Tablet,
                    value: 'flex-start',
                  },
                  width: { minWidth: Breakpoints.Tablet, value: '30%' },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['List_Labels'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['List_Labels'].style,
                    {
                      fontFamily: {
                        minWidth: Breakpoints.Laptop,
                        value: 'BaiJamjuree_500Medium',
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Email'}
              </Text>
            </View>
            {/* Orders */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: {
                    minWidth: Breakpoints.Tablet,
                    value: 'flex-start',
                  },
                  width: { minWidth: Breakpoints.Tablet, value: '12%' },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['List_Labels'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['List_Labels'].style,
                    {
                      fontFamily: {
                        minWidth: Breakpoints.Laptop,
                        value: 'BaiJamjuree_500Medium',
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Orders'}
              </Text>
            </View>
          </View>

          <PhotoReadyXanoApi.FetchGetAllUsersGET>
            {({ loading, error, data, refetchGetAllUsers }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <FlatList
                  data={(() => {
                    const e = fetchData;
                    console.log(e);
                    return e;
                  })()}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(listData, index) =>
                    listData?.id ?? listData?.uuid ?? index.toString()
                  }
                  keyboardShouldPersistTaps={'never'}
                  listKey={'ewi1zFXp'}
                  nestedScrollEnabled={false}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Tablet,
                              value: 'center',
                            },
                            flexDirection: {
                              minWidth: Breakpoints.Tablet,
                              value: 'row',
                            },
                            justifyContent: [
                              {
                                minWidth: Breakpoints.Tablet,
                                value: 'flex-start',
                              },
                              {
                                minWidth: Breakpoints.Desktop,
                                value: 'space-between',
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'space-between',
                              },
                            ],
                            minHeight: {
                              minWidth: Breakpoints.Tablet,
                              value: 60,
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {/* ID */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Desktop,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              width: [
                                { minWidth: Breakpoints.Tablet, value: '5%' },
                                { minWidth: Breakpoints.Desktop, value: 75 },
                                { minWidth: Breakpoints.Laptop, value: 75 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'List_Records_Texts'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'List_Records_Texts'
                                ].style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_400Regular',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {listData?.id}
                          </Text>
                        </View>
                        {/* Name */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'flex-start',
                                },
                                {
                                  minWidth: Breakpoints.Desktop,
                                  value: 'flex-start',
                                },
                              ],
                              width: [
                                { minWidth: Breakpoints.Tablet, value: '20%' },
                                { minWidth: Breakpoints.Desktop, value: 120 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'List_Records_Texts'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'List_Records_Texts'
                                ].style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_400Regular',
                                  },
                                  textTransform: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'capitalize',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {listData?.first_name} {listData?.last_name}
                          </Text>
                        </View>
                        {/* Email */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Tablet,
                                value: 'flex-start',
                              },
                              width: {
                                minWidth: Breakpoints.Tablet,
                                value: '30%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'List_Records_Texts'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'List_Records_Texts'
                                ].style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_400Regular',
                                  },
                                  textAlign: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'left',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {listData?.email}
                          </Text>
                        </View>
                        {/* Orders */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Tablet,
                                value: 'flex-start',
                              },
                              width: {
                                minWidth: Breakpoints.Tablet,
                                value: '12%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'List_Records_Texts'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'List_Records_Texts'
                                ].style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_400Regular',
                                  },
                                  textAlign: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'left',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {listData?._total_user_orders}
                          </Text>
                        </View>
                      </View>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  contentContainerStyle={StyleSheet.applyWidth(
                    {
                      flex: { minWidth: Breakpoints.Tablet, value: 1 },
                      paddingLeft: {
                        minWidth: Breakpoints.Tablet,
                        value: '2%',
                      },
                    },
                    dimensions.width
                  )}
                />
              );
            }}
          </PhotoReadyXanoApi.FetchGetAllUsersGET>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(UsersScreen);
