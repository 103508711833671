import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PhotoReadyXanoApi from '../apis/PhotoReadyXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Components from '../custom-files/Components';
import * as CustomCode from '../custom-files/CustomCode';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Pressable,
  ScreenContainer,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Modal, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const DashboardScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [isSyncing, setIsSyncing] = React.useState(false);
  const [showLogoutConfirmationPopUp, setShowLogoutConfirmationPopUp] =
    React.useState(false);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      {/* Header */}
      <View>
        {/* CustomHeader */}
        <Utils.CustomCodeErrorBoundary>
          <Components.MyHeader navigation={props.navigation} />
        </Utils.CustomCodeErrorBoundary>
      </View>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            flex: { minWidth: Breakpoints.Tablet, value: 1 },
            flexDirection: { minWidth: Breakpoints.Tablet, value: 'row' },
          },
          dimensions.width
        )}
      >
        {/* Menu */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: theme.colors['Theme_Color'],
              },
              borderColor: {
                minWidth: Breakpoints.Tablet,
                value: theme.colors['Divider'],
              },
              borderRightWidth: { minWidth: Breakpoints.Tablet, value: 1 },
              width: { minWidth: Breakpoints.Tablet, value: '18%' },
            },
            dimensions.width
          )}
        >
          {/* Dashboard */}
          <Surface
            elevation={0}
            {...GlobalStyles.SurfaceStyles(theme)['Menu Surface'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.SurfaceStyles(theme)['Menu Surface'].style,
              dimensions.width
            )}
          >
            <Pressable
              activeOpacity={0.3}
              style={StyleSheet.applyWidth(
                { height: { minWidth: Breakpoints.Tablet, value: '100%' } },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Tablet,
                      value: 'row',
                    },
                    height: { minWidth: Breakpoints.Tablet, value: '100%' },
                    padding: { minWidth: Breakpoints.Tablet, value: 8 },
                    paddingLeft: { minWidth: Breakpoints.Tablet, value: 15 },
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={theme.colors['Theme_Strong']}
                  name={'MaterialCommunityIcons/tablet-dashboard'}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Tablet,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Theme_Strong'],
                          },
                        ],
                        fontFamily: {
                          minWidth: Breakpoints.Laptop,
                          value: 'BaiJamjuree_600SemiBold',
                        },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Dashboard'}
                </Text>
              </View>
            </Pressable>
          </Surface>
          {/* Orders */}
          <Surface
            elevation={0}
            {...GlobalStyles.SurfaceStyles(theme)['Menu Surface'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.SurfaceStyles(theme)['Menu Surface'].style,
                { marginTop: { minWidth: Breakpoints.Tablet, value: 1 } }
              ),
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('OrdersScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              activeOpacity={0.3}
              style={StyleSheet.applyWidth(
                { height: { minWidth: Breakpoints.Tablet, value: '100%' } },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Tablet,
                      value: 'row',
                    },
                    height: { minWidth: Breakpoints.Tablet, value: '100%' },
                    padding: { minWidth: Breakpoints.Tablet, value: 8 },
                    paddingLeft: { minWidth: Breakpoints.Tablet, value: 15 },
                  },
                  dimensions.width
                )}
              >
                <Icon size={24} name={'Entypo/list'} />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Tablet,
                            value: 'Poppins_400Regular',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'BaiJamjuree_500Medium',
                          },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Orders'}
                </Text>
              </View>
            </Pressable>
          </Surface>
          {/* Users */}
          <Surface
            elevation={0}
            {...GlobalStyles.SurfaceStyles(theme)['Menu Surface'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.SurfaceStyles(theme)['Menu Surface'].style,
                { marginTop: { minWidth: Breakpoints.Tablet, value: 1 } }
              ),
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('UsersScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              activeOpacity={0.3}
              style={StyleSheet.applyWidth(
                { height: { minWidth: Breakpoints.Tablet, value: '100%' } },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Tablet,
                      value: 'row',
                    },
                    height: { minWidth: Breakpoints.Tablet, value: '100%' },
                    padding: { minWidth: Breakpoints.Tablet, value: 8 },
                    paddingLeft: { minWidth: Breakpoints.Tablet, value: 15 },
                  },
                  dimensions.width
                )}
              >
                <Icon size={24} name={'Feather/users'} />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: {
                          minWidth: Breakpoints.Tablet,
                          value: theme.colors['Medium'],
                        },
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Tablet,
                            value: 'Poppins_400Regular',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'BaiJamjuree_500Medium',
                          },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Users'}
                </Text>
              </View>
            </Pressable>
          </Surface>
          {/* Menu Bottom View */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: { minWidth: Breakpoints.Tablet, value: 1 },
                justifyContent: {
                  minWidth: Breakpoints.Tablet,
                  value: 'flex-end',
                },
                paddingBottom: { minWidth: Breakpoints.Tablet, value: 10 },
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      fontFamily: {
                        minWidth: Breakpoints.Tablet,
                        value: 'Poppins_400Regular',
                      },
                      fontSize: { minWidth: Breakpoints.Tablet, value: 11 },
                      lineHeight: { minWidth: Breakpoints.Tablet, value: 14 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Powered by:'}
              </Text>
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={Images.DraftbitLogo}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: { minWidth: Breakpoints.Tablet, value: 35 } }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              flex: { minWidth: Breakpoints.Laptop, value: 1 },
              flexDirection: { minWidth: Breakpoints.Laptop, value: 'column' },
            },
            dimensions.width
          )}
        >
          <PhotoReadyXanoApi.FetchDashboardGET
            handlers={{
              onData: fetchData => {
                try {
                  console.log(fetchData);
                } catch (err) {
                  console.error(err);
                }
              },
            }}
            refetchInterval={60000}
          >
            {({ loading, error, data, refetchDashboard }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Content */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: [
                          { minWidth: Breakpoints.Tablet, value: 'flex-start' },
                          { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                        ],
                        backgroundColor: {
                          minWidth: Breakpoints.Tablet,
                          value: theme.colors['Custom Color_2'],
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Tablet,
                          value: 'row',
                        },
                        flexWrap: {
                          minWidth: Breakpoints.Tablet,
                          value: 'wrap',
                        },
                        justifyContent: [
                          { minWidth: Breakpoints.Desktop, value: 'center' },
                          { minWidth: Breakpoints.Tablet, value: 'center' },
                        ],
                        padding: {
                          minWidth: Breakpoints.BigScreen,
                          value: '2%',
                        },
                        paddingTop: { minWidth: Breakpoints.Laptop, value: 20 },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Pending */}
                    <Surface
                      {...GlobalStyles.SurfaceStyles(theme)['Dashboard_Card']
                        .props}
                      elevation={3}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.SurfaceStyles(theme)['Dashboard_Card']
                          .style,
                        dimensions.width
                      )}
                    >
                      <Pressable
                        onPress={() => {
                          try {
                            setGlobalVariableValue({
                              key: 'FILTER_ORDERS',
                              value: 'in_progress',
                            });
                            navigation.navigate('OrdersScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        activeOpacity={0.3}
                        style={StyleSheet.applyWidth(
                          {
                            height: {
                              minWidth: Breakpoints.Tablet,
                              value: '100%',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {/* Count */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flex: { minWidth: Breakpoints.Tablet, value: 1 },
                              justifyContent: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                              paddingTop: {
                                minWidth: Breakpoints.Tablet,
                                value: '7%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Dashboard_Card_Count'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'Dashboard_Card_Count'
                                ].style,
                                {
                                  color: {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Custom Color_8'],
                                  },
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_700Bold',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'BaiJamjuree_400Regular',
                                    },
                                  ],
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.in_progress_orders}
                          </Text>
                        </View>
                        {/* Label */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                              backgroundColor: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: theme.colors['Divider'],
                                },
                                {
                                  minWidth: Breakpoints.Desktop,
                                  value: theme.colors['Divider'],
                                },
                              ],
                              height: [
                                { minWidth: Breakpoints.Tablet, value: '30%' },
                                { minWidth: Breakpoints.Desktop, value: '35%' },
                              ],
                              justifyContent: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Dashboard_Card_Label'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'Dashboard_Card_Label'
                                ].style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_400Regular',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Pending'}
                          </Text>
                          {/* orders */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Dashboard_Card_Label'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'Dashboard_Card_Label'
                                ].style,
                                {
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'Poppins_300Light',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 13,
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 13 },
                                  ],
                                  lineHeight: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 14,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'orders'}
                          </Text>
                        </View>
                      </Pressable>
                    </Surface>
                    {/* Completed */}
                    <Surface
                      elevation={0}
                      {...GlobalStyles.SurfaceStyles(theme)['Dashboard_Card']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.SurfaceStyles(theme)['Dashboard_Card']
                          .style,
                        dimensions.width
                      )}
                    >
                      <Pressable
                        onPress={() => {
                          try {
                            setGlobalVariableValue({
                              key: 'FILTER_ORDERS',
                              value: 'completed',
                            });
                            navigation.navigate('OrdersScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        activeOpacity={0.3}
                        style={StyleSheet.applyWidth(
                          {
                            height: {
                              minWidth: Breakpoints.Tablet,
                              value: '100%',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {/* Count */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flex: { minWidth: Breakpoints.Tablet, value: 1 },
                              justifyContent: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                              paddingTop: {
                                minWidth: Breakpoints.Tablet,
                                value: '7%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Dashboard_Card_Count'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'Dashboard_Card_Count'
                                ].style,
                                {
                                  color: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: theme.colors['Custom Color_5'],
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: theme.colors['Custom Color_5'],
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: theme.colors['Custom Color_5'],
                                    },
                                  ],
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_700Bold',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.completed_orders}
                          </Text>
                        </View>
                        {/* Label */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                              backgroundColor: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: theme.colors['Divider'],
                                },
                                {
                                  minWidth: Breakpoints.Desktop,
                                  value: theme.colors['Divider'],
                                },
                              ],
                              height: [
                                { minWidth: Breakpoints.Tablet, value: '30%' },
                                { minWidth: Breakpoints.Desktop, value: '35%' },
                              ],
                              justifyContent: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Dashboard_Card_Label'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'Dashboard_Card_Label'
                                ].style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_400Regular',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Completed'}
                          </Text>
                          {/* orders */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Dashboard_Card_Label'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'Dashboard_Card_Label'
                                ].style,
                                {
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'Poppins_300Light',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 13,
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 13 },
                                  ],
                                  lineHeight: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 14,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'orders'}
                          </Text>
                        </View>
                      </Pressable>
                    </Surface>
                    {/* Draft */}
                    <Surface
                      elevation={0}
                      {...GlobalStyles.SurfaceStyles(theme)['Dashboard_Card']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.SurfaceStyles(theme)['Dashboard_Card']
                          .style,
                        dimensions.width
                      )}
                    >
                      <Pressable
                        onPress={() => {
                          try {
                            setGlobalVariableValue({
                              key: 'FILTER_ORDERS',
                              value: 'draft',
                            });
                            navigation.navigate('OrdersScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        activeOpacity={0.3}
                        style={StyleSheet.applyWidth(
                          {
                            height: {
                              minWidth: Breakpoints.Tablet,
                              value: '100%',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {/* Count */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flex: { minWidth: Breakpoints.Tablet, value: 1 },
                              justifyContent: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                              paddingTop: {
                                minWidth: Breakpoints.Tablet,
                                value: '7%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Dashboard_Card_Count'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'Dashboard_Card_Count'
                                ].style,
                                {
                                  color: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: theme.colors['Custom Color_4'],
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: theme.colors['Custom Color_4'],
                                    },
                                  ],
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_700Bold',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.draft_orders}
                          </Text>
                        </View>
                        {/* Label */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                              backgroundColor: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: theme.colors['Divider'],
                                },
                                {
                                  minWidth: Breakpoints.Desktop,
                                  value: theme.colors['Divider'],
                                },
                              ],
                              height: [
                                { minWidth: Breakpoints.Tablet, value: '30%' },
                                { minWidth: Breakpoints.Desktop, value: '35%' },
                              ],
                              justifyContent: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Dashboard_Card_Label'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'Dashboard_Card_Label'
                                ].style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_400Regular',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Draft'}
                          </Text>
                          {/* orders */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Dashboard_Card_Label'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'Dashboard_Card_Label'
                                ].style,
                                {
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'Poppins_300Light',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 13,
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 13 },
                                  ],
                                  lineHeight: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 14,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'orders'}
                          </Text>
                        </View>
                      </Pressable>
                    </Surface>
                    {/* Users */}
                    <Surface
                      elevation={0}
                      {...GlobalStyles.SurfaceStyles(theme)['Dashboard_Card']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.SurfaceStyles(theme)['Dashboard_Card']
                          .style,
                        dimensions.width
                      )}
                    >
                      <Pressable
                        onPress={() => {
                          try {
                            navigation.navigate('UsersScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        activeOpacity={0.3}
                        style={StyleSheet.applyWidth(
                          {
                            height: {
                              minWidth: Breakpoints.Tablet,
                              value: '100%',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {/* Count */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flex: { minWidth: Breakpoints.Tablet, value: 1 },
                              justifyContent: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                              paddingTop: {
                                minWidth: Breakpoints.Tablet,
                                value: '7%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Dashboard_Card_Count'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'Dashboard_Card_Count'
                                ].style,
                                {
                                  color: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: theme.colors['Strong'],
                                  },
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_700Bold',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.users_count}
                          </Text>
                        </View>
                        {/* Label */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                              backgroundColor: {
                                minWidth: Breakpoints.Tablet,
                                value: theme.colors['Divider'],
                              },
                              height: [
                                { minWidth: Breakpoints.Tablet, value: '30%' },
                                { minWidth: Breakpoints.Desktop, value: '35%' },
                              ],
                              justifyContent: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Dashboard_Card_Label'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'Dashboard_Card_Label'
                                ].style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_400Regular',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Users'}
                          </Text>
                          {/* count */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Dashboard_Card_Label'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'Dashboard_Card_Label'
                                ].style,
                                {
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'Poppins_300Light',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 13,
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 13 },
                                  ],
                                  lineHeight: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 14,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'count'}
                          </Text>
                        </View>
                      </Pressable>
                    </Surface>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: [
                          { minWidth: Breakpoints.BigScreen, value: 'center' },
                          { minWidth: Breakpoints.Desktop, value: 'center' },
                          { minWidth: Breakpoints.Laptop, value: 'center' },
                        ],
                        gap: { minWidth: Breakpoints.Laptop, value: 1 },
                        margin: { minWidth: Breakpoints.Laptop, value: 50 },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            flex: { minWidth: Breakpoints.Desktop, value: 1 },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'BaiJamjuree_500Medium',
                            },
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 0,
                            },
                            marginRight: [
                              { minWidth: Breakpoints.Laptop, value: 30 },
                              { minWidth: Breakpoints.Desktop, value: 50 },
                              { minWidth: Breakpoints.BigScreen, value: 80 },
                            ],
                            textAlign: {
                              minWidth: Breakpoints.Desktop,
                              value: 'left',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Pending Photos'}
                    </Text>
                    {/* Pending */}
                    <Surface
                      {...GlobalStyles.SurfaceStyles(theme)['Dashboard_Card']
                        .props}
                      elevation={3}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Dashboard_Card']
                            .style,
                          {
                            marginTop: {
                              minWidth: Breakpoints.Laptop,
                              value: 10,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Count */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flex: { minWidth: Breakpoints.Tablet, value: 1 },
                            justifyContent: {
                              minWidth: Breakpoints.Tablet,
                              value: 'center',
                            },
                            paddingTop: {
                              minWidth: Breakpoints.Tablet,
                              value: '7%',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)[
                            'Dashboard_Card_Count'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)[
                                'Dashboard_Card_Count'
                              ].style,
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Custom Color_8'],
                                },
                                fontFamily: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_700Bold',
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'BaiJamjuree_400Regular',
                                  },
                                ],
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {fetchData?.pendingSync}
                        </Text>
                      </View>

                      <Pressable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setIsSyncing(true);
                              const result = (
                                await PhotoReadyXanoApi.photosSyncGET(Constants)
                              )?.json;
                              setIsSyncing(false);
                              if (result?.status === 'Success!') {
                                await refetchDashboard();
                              } else {
                              }
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        activeOpacity={0.3}
                        disabled={fetchData?.pendingSync === 0}
                      >
                        {/* Label */}
                        <>
                          {!(fetchData?.pendingSync > 0) ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'center',
                                  },
                                  backgroundColor: [
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: theme.colors['Divider'],
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: theme.colors['Secondary'],
                                    },
                                  ],
                                  flexDirection: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'row',
                                  },
                                  height: [
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: '30%',
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 45 },
                                  ],
                                  justifyContent: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'center',
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)[
                                  'Dashboard_Card_Label'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'Dashboard_Card_Label'
                                    ].style,
                                    {
                                      color: {
                                        minWidth: Breakpoints.Laptop,
                                        value: theme.colors['Error'],
                                      },
                                      fontFamily: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'BaiJamjuree_700Bold',
                                      },
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Sync Now'}
                              </Text>
                              <>
                                {!isSyncing ? null : (
                                  <ActivityIndicator
                                    animating={true}
                                    hidesWhenStopped={true}
                                    size={'small'}
                                    {...GlobalStyles.ActivityIndicatorStyles(
                                      theme
                                    )['Activity Indicator'].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ActivityIndicatorStyles(
                                        theme
                                      )['Activity Indicator'].style,
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>
                          )}
                        </>
                        {/* Green */}
                        <>
                          {!(fetchData?.pendingSync === 0) ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'center',
                                  },
                                  backgroundColor: [
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: theme.colors['Divider'],
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: theme.colors['Custom Color_5'],
                                    },
                                  ],
                                  flexDirection: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'row',
                                  },
                                  height: [
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: '30%',
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 45 },
                                  ],
                                  justifyContent: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'center',
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={theme.colors['Surface']}
                                name={'AntDesign/checkcircle'}
                                size={18}
                                style={StyleSheet.applyWidth(
                                  {
                                    marginRight: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 6,
                                    },
                                  },
                                  dimensions.width
                                )}
                              />
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)[
                                  'Dashboard_Card_Label'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'Dashboard_Card_Label'
                                    ].style,
                                    {
                                      color: {
                                        minWidth: Breakpoints.Laptop,
                                        value: theme.colors['Surface'],
                                      },
                                      fontFamily: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'BaiJamjuree_700Bold',
                                      },
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'All Synced'}
                              </Text>
                            </View>
                          )}
                        </>
                      </Pressable>
                    </Surface>
                  </View>
                </>
              );
            }}
          </PhotoReadyXanoApi.FetchDashboardGET>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(DashboardScreen);
