export default {
  Aclonica_400Regular: require('../assets/fonts/Aclonica_400Regular.ttf'),
  BaiJamjuree_300Light: require('../assets/fonts/BaiJamjuree_300Light.ttf'),
  BaiJamjuree_400Regular: require('../assets/fonts/BaiJamjuree_400Regular.ttf'),
  BaiJamjuree_500Medium: require('../assets/fonts/BaiJamjuree_500Medium.ttf'),
  BaiJamjuree_600SemiBold: require('../assets/fonts/BaiJamjuree_600SemiBold.ttf'),
  BaiJamjuree_700Bold: require('../assets/fonts/BaiJamjuree_700Bold.ttf'),
  Comfortaa_700Bold: require('../assets/fonts/Comfortaa_700Bold.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_300Light: require('../assets/fonts/Poppins_300Light.ttf'),
  Poppins_500Medium: require('../assets/fonts/Poppins_500Medium.ttf'),
  Poppins_700Bold: require('../assets/fonts/Poppins_700Bold.ttf'),
};
