import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PhotoReadyXanoApi from '../apis/PhotoReadyXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Components from '../custom-files/Components';
import * as CustomCode from '../custom-files/CustomCode';
import changeDateFormat from '../global-functions/changeDateFormat';
import urlEncode from '../global-functions/urlEncode';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Pressable,
  ScreenContainer,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const OrdersScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [isLoading, setIsLoading] = React.useState(false);
  const [showCompleteOrderModal, setShowCompleteOrderModal] =
    React.useState(false);
  const [tempItem, setTempItem] = React.useState({});
  const totalOrderCost = item => {
    return item.cost_per_photo * item._total_photos;
  };
  const photoReadyXanoCompleteOrderPOST =
    PhotoReadyXanoApi.useCompleteOrderPOST();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
            backgroundColor: {
              minWidth: Breakpoints.Tablet,
              value: theme.colors['Theme_Color'],
            },
            flexDirection: { minWidth: Breakpoints.Tablet, value: 'row' },
            justifyContent: {
              minWidth: Breakpoints.Tablet,
              value: 'space-between',
            },
          },
          dimensions.width
        )}
      >
        {/* CustomHeader */}
        <Utils.CustomCodeErrorBoundary>
          <Components.MyHeader navigation={props.navigation} />
        </Utils.CustomCodeErrorBoundary>
      </View>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            flex: { minWidth: Breakpoints.Tablet, value: 1 },
            flexDirection: { minWidth: Breakpoints.Tablet, value: 'row' },
          },
          dimensions.width
        )}
      >
        {/* Menu */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: theme.colors['Theme_Color'],
              },
              width: { minWidth: Breakpoints.Tablet, value: '18%' },
            },
            dimensions.width
          )}
        >
          {/* Dashboard */}
          <Surface
            elevation={0}
            {...GlobalStyles.SurfaceStyles(theme)['Menu Surface'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.SurfaceStyles(theme)['Menu Surface'].style,
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('DashboardScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              activeOpacity={0.3}
              style={StyleSheet.applyWidth(
                { height: { minWidth: Breakpoints.Tablet, value: '100%' } },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Tablet,
                      value: 'row',
                    },
                    height: { minWidth: Breakpoints.Tablet, value: '100%' },
                    padding: { minWidth: Breakpoints.Tablet, value: 8 },
                    paddingLeft: { minWidth: Breakpoints.Tablet, value: 15 },
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'MaterialCommunityIcons/tablet-dashboard'}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Tablet,
                            value: 'Poppins_400Regular',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'BaiJamjuree_400Regular',
                          },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Dashboard'}
                </Text>
              </View>
            </Pressable>
          </Surface>
          {/* Orders */}
          <Surface
            elevation={0}
            {...GlobalStyles.SurfaceStyles(theme)['Menu Surface'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.SurfaceStyles(theme)['Menu Surface'].style,
                { marginTop: { minWidth: Breakpoints.Tablet, value: 1 } }
              ),
              dimensions.width
            )}
          >
            <Pressable
              activeOpacity={0.3}
              style={StyleSheet.applyWidth(
                { height: { minWidth: Breakpoints.Tablet, value: '100%' } },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    backgroundColor: {
                      minWidth: Breakpoints.Tablet,
                      value: theme.colors['Custom Color_2'],
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Tablet,
                      value: 'row',
                    },
                    height: { minWidth: Breakpoints.Tablet, value: '100%' },
                    padding: { minWidth: Breakpoints.Tablet, value: 8 },
                    paddingLeft: { minWidth: Breakpoints.Tablet, value: 15 },
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={theme.colors['Theme_Strong']}
                  name={'Entypo/list'}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Tablet,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Theme_Strong'],
                          },
                        ],
                        fontFamily: {
                          minWidth: Breakpoints.Laptop,
                          value: 'BaiJamjuree_500Medium',
                        },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Orders'}
                </Text>
              </View>
            </Pressable>
          </Surface>
          {/* Users */}
          <Surface
            elevation={0}
            {...GlobalStyles.SurfaceStyles(theme)['Menu Surface'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.SurfaceStyles(theme)['Menu Surface'].style,
                { marginTop: { minWidth: Breakpoints.Tablet, value: 1 } }
              ),
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('UsersScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              activeOpacity={0.3}
              style={StyleSheet.applyWidth(
                { height: { minWidth: Breakpoints.Tablet, value: '100%' } },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Tablet,
                      value: 'row',
                    },
                    height: { minWidth: Breakpoints.Tablet, value: '100%' },
                    padding: { minWidth: Breakpoints.Tablet, value: 8 },
                    paddingLeft: { minWidth: Breakpoints.Tablet, value: 15 },
                  },
                  dimensions.width
                )}
              >
                <Icon size={24} name={'Feather/users'} />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Tablet,
                            value: 'Poppins_400Regular',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'BaiJamjuree_400Regular',
                          },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Users'}
                </Text>
              </View>
            </Pressable>
          </Surface>
          {/* Menu Bottom View */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: { minWidth: Breakpoints.Tablet, value: 1 },
                justifyContent: {
                  minWidth: Breakpoints.Tablet,
                  value: 'flex-end',
                },
                paddingBottom: { minWidth: Breakpoints.Tablet, value: 10 },
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Tablet,
                          value: 'Poppins_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'BaiJamjuree_400Regular',
                        },
                      ],
                      fontSize: { minWidth: Breakpoints.Tablet, value: 11 },
                      lineHeight: { minWidth: Breakpoints.Tablet, value: 14 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Powered by:'}
              </Text>
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={Images.DraftbitLogo}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: { minWidth: Breakpoints.Tablet, value: 35 } }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
        </View>

        <PhotoReadyXanoApi.FetchOrdersGET status={Constants['FILTER_ORDERS']}>
          {({ loading, error, data, refetchOrders }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                {/* Content */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Tablet,
                        value: 'stretch',
                      },
                      flex: { minWidth: Breakpoints.Tablet, value: 1 },
                      flexDirection: {
                        minWidth: Breakpoints.Tablet,
                        value: 'column',
                      },
                      flexWrap: { minWidth: Breakpoints.Tablet, value: 'wrap' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Filters Orders */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: [
                          { minWidth: Breakpoints.Tablet, value: 'flex-end' },
                          { minWidth: Breakpoints.Desktop, value: 'center' },
                          { minWidth: Breakpoints.Laptop, value: 'center' },
                        ],
                        flexDirection: [
                          { minWidth: Breakpoints.Desktop, value: 'row' },
                          { minWidth: Breakpoints.Laptop, value: 'row' },
                        ],
                        height: [
                          { minWidth: Breakpoints.Tablet, value: 50 },
                          { minWidth: Breakpoints.Desktop, value: 65 },
                          { minWidth: Breakpoints.Laptop, value: 65 },
                        ],
                        justifyContent: [
                          { minWidth: Breakpoints.Tablet, value: 'center' },
                          {
                            minWidth: Breakpoints.Desktop,
                            value: 'flex-start',
                          },
                          { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                        ],
                        paddingLeft: [
                          { minWidth: Breakpoints.Desktop, value: 20 },
                          { minWidth: Breakpoints.Laptop, value: 20 },
                        ],
                        paddingRight: {
                          minWidth: Breakpoints.Tablet,
                          value: 20,
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Pending */}
                    <Pressable
                      onPress={() => {
                        try {
                          setGlobalVariableValue({
                            key: 'FILTER_ORDERS',
                            value: 'in_progress',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      activeOpacity={0.3}
                    >
                      {/* seleted */}
                      <>
                        {!(
                          Constants['FILTER_ORDERS'] === 'in_progress'
                        ) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                backgroundColor: [
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: theme.colors['Secondary'],
                                  },
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: theme.colors['Error'],
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Error'],
                                  },
                                ],
                                borderRadius: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 6,
                                },
                                flexDirection: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'row',
                                },
                                height: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 35,
                                },
                                justifyContent: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                padding: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 12,
                                },
                                paddingBottom: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 5,
                                },
                                paddingTop: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 5,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: {
                                      minWidth: Breakpoints.Tablet,
                                      value: theme.colors['Custom Color_2'],
                                    },
                                    fontFamily: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_500Medium',
                                    },
                                    marginLeft: {
                                      minWidth: Breakpoints.Desktop,
                                      value: null,
                                    },
                                    textTransform: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'capitalize',
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Pending'}
                            </Text>
                          </View>
                        )}
                      </>
                      {/* unseleted */}
                      <>
                        {!(
                          Constants['FILTER_ORDERS'] !== 'in_progress'
                        ) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                backgroundColor: {
                                  minWidth: Breakpoints.Desktop,
                                  value: theme.colors['Surface'],
                                },
                                borderColor: [
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: theme.colors['Error'],
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Error'],
                                  },
                                ],
                                borderRadius: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 6,
                                },
                                borderWidth: [
                                  { minWidth: Breakpoints.Desktop, value: 1 },
                                  { minWidth: Breakpoints.Laptop, value: 1 },
                                ],
                                flexDirection: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'row',
                                },
                                height: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 35,
                                },
                                justifyContent: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                padding: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 12,
                                },
                                paddingBottom: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 5,
                                },
                                paddingTop: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 5,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: [
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: theme.colors['Custom Color_2'],
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: theme.colors['Strong'],
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: theme.colors['Strong'],
                                      },
                                    ],
                                    fontFamily: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_500Medium',
                                    },
                                    marginLeft: {
                                      minWidth: Breakpoints.Desktop,
                                      value: null,
                                    },
                                    textTransform: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'capitalize',
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Pending'}
                            </Text>
                          </View>
                        )}
                      </>
                    </Pressable>
                    {/* Completed */}
                    <Pressable
                      onPress={() => {
                        try {
                          setGlobalVariableValue({
                            key: 'FILTER_ORDERS',
                            value: 'completed',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      activeOpacity={0.3}
                      style={StyleSheet.applyWidth(
                        {
                          marginLeft: [
                            { minWidth: Breakpoints.Desktop, value: 25 },
                            { minWidth: Breakpoints.Laptop, value: 25 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* seleted */}
                      <>
                        {!(
                          Constants['FILTER_ORDERS'] === 'completed'
                        ) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                backgroundColor: [
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: theme.colors['Secondary'],
                                  },
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: theme.colors['Custom Color_5'],
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Custom Color_5'],
                                  },
                                ],
                                borderRadius: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 6,
                                },
                                flexDirection: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'row',
                                },
                                height: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 35,
                                },
                                justifyContent: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                padding: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 12,
                                },
                                paddingBottom: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 5,
                                },
                                paddingTop: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 5,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: {
                                      minWidth: Breakpoints.Tablet,
                                      value: theme.colors['Custom Color_2'],
                                    },
                                    fontFamily: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_500Medium',
                                    },
                                    marginLeft: {
                                      minWidth: Breakpoints.Desktop,
                                      value: null,
                                    },
                                    textTransform: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'capitalize',
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Completed'}
                            </Text>
                          </View>
                        )}
                      </>
                      {/* unseleted */}
                      <>
                        {!(
                          Constants['FILTER_ORDERS'] !== 'completed'
                        ) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                backgroundColor: [
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: theme.colors['Surface'],
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Surface'],
                                  },
                                ],
                                borderColor: [
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: theme.colors['Custom Color_5'],
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Custom Color_5'],
                                  },
                                ],
                                borderRadius: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 6,
                                },
                                borderWidth: [
                                  { minWidth: Breakpoints.Desktop, value: 1 },
                                  { minWidth: Breakpoints.Laptop, value: 1 },
                                ],
                                flexDirection: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'row',
                                },
                                height: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 35,
                                },
                                justifyContent: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                padding: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 12,
                                },
                                paddingBottom: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 5,
                                },
                                paddingTop: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 5,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: [
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: theme.colors['Custom Color_2'],
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: theme.colors['Strong'],
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: theme.colors['Strong'],
                                      },
                                    ],
                                    fontFamily: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_500Medium',
                                    },
                                    marginLeft: {
                                      minWidth: Breakpoints.Desktop,
                                      value: null,
                                    },
                                    textTransform: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'capitalize',
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Completed'}
                            </Text>
                          </View>
                        )}
                      </>
                    </Pressable>
                    {/* Draft */}
                    <Pressable
                      onPress={() => {
                        try {
                          setGlobalVariableValue({
                            key: 'FILTER_ORDERS',
                            value: 'draft',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      activeOpacity={0.3}
                      style={StyleSheet.applyWidth(
                        {
                          marginLeft: [
                            { minWidth: Breakpoints.Desktop, value: 25 },
                            { minWidth: Breakpoints.Laptop, value: 25 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* seleted */}
                      <>
                        {!(Constants['FILTER_ORDERS'] === 'draft') ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                backgroundColor: [
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: theme.colors['Secondary'],
                                  },
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: theme.colors['Custom Color_4'],
                                  },
                                ],
                                borderRadius: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 6,
                                },
                                flexDirection: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'row',
                                },
                                height: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 35,
                                },
                                justifyContent: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                minWidth: [
                                  { minWidth: Breakpoints.Desktop, value: 100 },
                                  { minWidth: Breakpoints.Laptop, value: 100 },
                                ],
                                padding: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 12,
                                },
                                paddingBottom: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 5,
                                },
                                paddingTop: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 5,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: {
                                      minWidth: Breakpoints.Tablet,
                                      value: theme.colors['Custom Color_2'],
                                    },
                                    fontFamily: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_500Medium',
                                    },
                                    marginLeft: {
                                      minWidth: Breakpoints.Desktop,
                                      value: null,
                                    },
                                    textTransform: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'capitalize',
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Draft'}
                            </Text>
                          </View>
                        )}
                      </>
                      {/* unseleted */}
                      <>
                        {!(Constants['FILTER_ORDERS'] !== 'draft') ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                backgroundColor: [
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: theme.colors['Surface'],
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Surface'],
                                  },
                                ],
                                borderColor: [
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: theme.colors['Custom Color_4'],
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: theme.colors['Custom Color_4'],
                                  },
                                ],
                                borderRadius: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 6,
                                },
                                borderWidth: [
                                  { minWidth: Breakpoints.Desktop, value: 1 },
                                  { minWidth: Breakpoints.Laptop, value: 1 },
                                ],
                                flexDirection: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'row',
                                },
                                height: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 35,
                                },
                                justifyContent: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                minWidth: [
                                  { minWidth: Breakpoints.Desktop, value: 100 },
                                  { minWidth: Breakpoints.Laptop, value: 100 },
                                ],
                                padding: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 12,
                                },
                                paddingBottom: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 5,
                                },
                                paddingTop: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 5,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: [
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: theme.colors['Custom Color_2'],
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: theme.colors['Strong'],
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: theme.colors['Strong'],
                                      },
                                    ],
                                    fontFamily: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_500Medium',
                                    },
                                    marginLeft: {
                                      minWidth: Breakpoints.Desktop,
                                      value: null,
                                    },
                                    textTransform: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'capitalize',
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Draft'}
                            </Text>
                          </View>
                        )}
                      </>
                    </Pressable>
                  </View>
                  {/* Labels */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        backgroundColor: [
                          {
                            minWidth: Breakpoints.Tablet,
                            value: theme.colors['Divider'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Divider'],
                          },
                        ],
                        flexDirection: {
                          minWidth: Breakpoints.Tablet,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Tablet, value: 60 },
                        justifyContent: [
                          { minWidth: Breakpoints.Tablet, value: 'flex-start' },
                          {
                            minWidth: Breakpoints.Desktop,
                            value: 'space-between',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'space-between',
                          },
                        ],
                        paddingLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: '2%',
                        },
                        paddingRight: [
                          { minWidth: Breakpoints.Desktop, value: '2%' },
                          { minWidth: Breakpoints.Laptop, value: '2%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {/* ID */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: [
                            { minWidth: Breakpoints.Desktop, value: 'center' },
                            { minWidth: Breakpoints.Laptop, value: 'center' },
                          ],
                          justifyContent: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                          width: [
                            { minWidth: Breakpoints.Desktop, value: 50 },
                            { minWidth: Breakpoints.Laptop, value: 50 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['List_Labels'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['List_Labels'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'BaiJamjuree_500Medium',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'ID'}
                      </Text>
                    </View>
                    {/* Placed On */}
                    <>
                      {!(Constants['FILTER_ORDERS'] !== 'draft') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Desktop,
                                  value: 'center',
                                },
                              ],
                              marginLeft: {
                                minWidth: Breakpoints.Laptop,
                                value: 10,
                              },
                              width: [
                                { minWidth: Breakpoints.Desktop, value: 150 },
                                { minWidth: Breakpoints.BigScreen, value: 200 },
                                { minWidth: Breakpoints.Laptop, value: 130 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['List_Labels']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['List_Labels']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_500Medium',
                                  },
                                  marginLeft: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 0,
                                  },
                                  textAlign: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'left',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Placed on'}
                          </Text>
                        </View>
                      )}
                    </>
                    {/* Customer */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          width: [
                            { minWidth: Breakpoints.Desktop, value: 200 },
                            { minWidth: Breakpoints.Laptop, value: 160 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['List_Labels'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['List_Labels'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'BaiJamjuree_500Medium',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Customer'}
                      </Text>
                    </View>
                    {/* Price */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Desktop,
                            value: 'center',
                          },
                          width: [
                            { minWidth: Breakpoints.Desktop, value: 60 },
                            { minWidth: Breakpoints.Laptop, value: 60 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['List_Labels'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['List_Labels'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'BaiJamjuree_500Medium',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Price'}
                      </Text>
                    </View>
                    {/* Quantity */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          width: [
                            { minWidth: Breakpoints.Desktop, value: 75 },
                            { minWidth: Breakpoints.Laptop, value: 75 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['List_Labels'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['List_Labels'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'BaiJamjuree_500Medium',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Photos'}
                      </Text>
                    </View>
                    {/* Folder */}
                    <>
                      {!(Constants['FILTER_ORDERS'] !== 'draft') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              width: [
                                { minWidth: Breakpoints.Desktop, value: 200 },
                                { minWidth: Breakpoints.Laptop, value: 200 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['List_Labels']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['List_Labels']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_500Medium',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Folder'}
                          </Text>
                        </View>
                      )}
                    </>
                    {/* Actions */}
                    <>
                      {!(
                        Constants['FILTER_ORDERS'] === 'in_progress'
                      ) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              width: {
                                minWidth: Breakpoints.Desktop,
                                value: 200,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['List_Labels']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['List_Labels']
                                  .style,
                                {
                                  fontFamily: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'BaiJamjuree_500Medium',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Actions'}
                          </Text>
                        </View>
                      )}
                    </>
                  </View>
                  <FlatList
                    data={fetchData}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'cODH0UcU'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                              borderBottomWidth: {
                                minWidth: Breakpoints.Tablet,
                                value: 1,
                              },
                              borderColor: {
                                minWidth: Breakpoints.Tablet,
                                value: theme.colors['Divider'],
                              },
                              flexDirection: {
                                minWidth: Breakpoints.Tablet,
                                value: 'row',
                              },
                              justifyContent: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'flex-start',
                                },
                                {
                                  minWidth: Breakpoints.Desktop,
                                  value: 'space-between',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'space-between',
                                },
                              ],
                              minHeight: {
                                minWidth: Breakpoints.Tablet,
                                value: 60,
                              },
                              paddingLeft: {
                                minWidth: Breakpoints.Laptop,
                                value: '2%',
                              },
                              paddingRight: [
                                { minWidth: Breakpoints.Desktop, value: '2%' },
                                { minWidth: Breakpoints.Laptop, value: '2%' },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {/* ID */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: [
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: 'center',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'center',
                                  },
                                ],
                                width: [
                                  { minWidth: Breakpoints.Desktop, value: 50 },
                                  { minWidth: Breakpoints.Laptop, value: 50 },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'List_Records_Texts'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)[
                                    'List_Records_Texts'
                                  ].style,
                                  {
                                    fontFamily: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_400Regular',
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData?.id}
                            </Text>
                          </View>
                          {/* Placed On */}
                          <>
                            {!(
                              Constants['FILTER_ORDERS'] !== 'draft'
                            ) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: [
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'center',
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 'center',
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'center',
                                      },
                                    ],
                                    width: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 150,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 200,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 130,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'List_Records_Texts'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'List_Records_Texts'
                                      ].style,
                                      {
                                        fontFamily: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'BaiJamjuree_400Regular',
                                        },
                                        textAlign: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'left',
                                        },
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {changeDateFormat(
                                    listData?.placed_at,
                                    Constants['APP_DATE_FORMAT']
                                  )}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* customer  */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                width: [
                                  { minWidth: Breakpoints.Desktop, value: 200 },
                                  { minWidth: Breakpoints.Laptop, value: 160 },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'List_Records_Texts'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)[
                                    'List_Records_Texts'
                                  ].style,
                                  {
                                    fontFamily: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_400Regular',
                                    },
                                    marginLeft: {
                                      minWidth: Breakpoints.Laptop,
                                      value: null,
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData?.user_details?.first_name}{' '}
                              {listData?.user_details?.last_name}
                            </Text>
                          </View>
                          {/* Price */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: [
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'center',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'flex-start',
                                  },
                                ],
                                width: [
                                  { minWidth: Breakpoints.Desktop, value: 60 },
                                  { minWidth: Breakpoints.Laptop, value: 60 },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'List_Records_Texts'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)[
                                    'List_Records_Texts'
                                  ].style,
                                  {
                                    fontFamily: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_400Regular',
                                    },
                                    textAlign: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'left',
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'$'}
                              {(() => {
                                const e = totalOrderCost(listData);
                                console.log(e);
                                return e;
                              })()}
                            </Text>
                          </View>
                          {/* Quanitity */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                width: [
                                  { minWidth: Breakpoints.Desktop, value: 75 },
                                  { minWidth: Breakpoints.Laptop, value: 75 },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'List_Records_Texts'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)[
                                    'List_Records_Texts'
                                  ].style,
                                  {
                                    fontFamily: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_400Regular',
                                    },
                                    textAlign: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'left',
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData?._total_photos}
                            </Text>
                          </View>
                          {/* Folder */}
                          <>
                            {!(
                              Constants['FILTER_ORDERS'] !== 'draft'
                            ) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'center',
                                    },
                                    width: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 200,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 200,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                <Pressable
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        if (
                                          listData?.status === 'in_progress'
                                        ) {
                                          await WebBrowser.openBrowserAsync(
                                            `https://www.dropbox.com/home/Apps/PhotoReady%20App/orders/${urlEncode(
                                              listData?.folder_name
                                            )}`
                                          );
                                        } else {
                                          await WebBrowser.openBrowserAsync(
                                            `${listData?.folder_link}`
                                          );
                                        }
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  activeOpacity={0.3}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: {
                                          minWidth: Breakpoints.Desktop,
                                          value: 'center',
                                        },
                                        justifyContent: {
                                          minWidth: Breakpoints.Desktop,
                                          value: 'center',
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'List_Records_Texts'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'List_Records_Texts'
                                          ].style,
                                          {
                                            color: [
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value:
                                                  theme.colors[
                                                    'Custom Color_7'
                                                  ],
                                              },
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value:
                                                  theme.colors[
                                                    'Custom Color_7'
                                                  ],
                                              },
                                            ],
                                            fontFamily: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'BaiJamjuree_400Regular',
                                            },
                                            textAlign: {
                                              minWidth: Breakpoints.Tablet,
                                              value: 'left',
                                            },
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.folder_name}
                                    </Text>
                                  </View>
                                </Pressable>
                              </View>
                            )}
                          </>
                          {/* Actions and Status */}
                          <>
                            {!(
                              Constants['FILTER_ORDERS'] === 'in_progress'
                            ) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'center',
                                    },
                                    width: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 200,
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                <Pressable
                                  onPress={() => {
                                    try {
                                      setShowCompleteOrderModal(true);
                                      setTempItem(listData);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  activeOpacity={0.3}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 'center',
                                        },
                                        flexDirection: {
                                          minWidth: Breakpoints.Desktop,
                                          value: 'row',
                                        },
                                        height: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 50,
                                        },
                                        justifyContent: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 'center',
                                        },
                                        width: [
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 200,
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!(
                                        dimensions.width >= Breakpoints.Desktop
                                      ) ? null : (
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'List_Records_Texts'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'List_Records_Texts'
                                              ].style,
                                              {
                                                fontFamily: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value:
                                                    'BaiJamjuree_400Regular',
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'In Progress'}
                                        </Text>
                                      )}
                                    </>
                                    <Icon
                                      color={theme.colors['Theme_Strong']}
                                      name={
                                        'MaterialCommunityIcons/briefcase-edit'
                                      }
                                      size={20}
                                      style={StyleSheet.applyWidth(
                                        {
                                          marginLeft: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 7,
                                          },
                                        },
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                </Pressable>
                              </View>
                            )}
                          </>
                        </View>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    contentContainerStyle={StyleSheet.applyWidth(
                      {
                        paddingLeft: [
                          { minWidth: Breakpoints.Tablet, value: '2%' },
                          { minWidth: Breakpoints.Laptop, value: '0%' },
                        ],
                      },
                      dimensions.width
                    )}
                  />
                  {/* Complete Order */}
                  <Modal
                    animationType={'none'}
                    transparent={true}
                    visible={showCompleteOrderModal}
                  >
                    {/* BG */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: {
                            minWidth: Breakpoints.Tablet,
                            value: theme.colors['Strong'],
                          },
                          bottom: { minWidth: Breakpoints.Tablet, value: 0 },
                          left: { minWidth: Breakpoints.Tablet, value: 0 },
                          opacity: { minWidth: Breakpoints.Tablet, value: 0.3 },
                          position: {
                            minWidth: Breakpoints.Tablet,
                            value: 'absolute',
                          },
                          right: { minWidth: Breakpoints.Tablet, value: 0 },
                          top: { minWidth: Breakpoints.Tablet, value: 0 },
                        },
                        dimensions.width
                      )}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                          flex: { minWidth: Breakpoints.Tablet, value: 1 },
                          justifyContent: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      {/* Pop up */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: {
                              minWidth: Breakpoints.Tablet,
                              value: theme.colors['Custom Color_2'],
                            },
                            borderRadius: [
                              { minWidth: Breakpoints.Tablet, value: 20 },
                              { minWidth: Breakpoints.Desktop, value: 10 },
                            ],
                            height: {
                              minWidth: Breakpoints.Tablet,
                              value: 200,
                            },
                            overflow: {
                              minWidth: Breakpoints.Tablet,
                              value: 'hidden',
                            },
                            width: { minWidth: Breakpoints.Tablet, value: 350 },
                          },
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'BaiJamjuree_500Medium',
                                },
                                fontSize: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 21,
                                },
                                padding: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 20,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Complete the Order?'}
                        </Text>
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              borderColor: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: theme.colors['Divider'],
                                },
                                {
                                  minWidth: Breakpoints.Desktop,
                                  value: 'rgba(0, 0, 0, 0)',
                                },
                              ],
                              borderTopWidth: {
                                minWidth: Breakpoints.Tablet,
                                value: 1,
                              },
                              flex: { minWidth: Breakpoints.Tablet, value: 1 },
                              justifyContent: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Description */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'Poppins_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'Poppins_300Light',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_400Regular',
                                    },
                                  ],
                                  fontSize: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 17,
                                  },
                                  padding: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 20,
                                  },
                                  paddingTop: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 0,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Are you sure to mark this order as completed?'}
                          </Text>
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Tablet,
                                value: 'center',
                              },
                              borderColor: {
                                minWidth: Breakpoints.Tablet,
                                value: theme.colors['Divider'],
                              },
                              borderWidth: {
                                minWidth: Breakpoints.Tablet,
                                value: 1,
                              },
                              flexDirection: {
                                minWidth: Breakpoints.Tablet,
                                value: 'row',
                              },
                              height: {
                                minWidth: Breakpoints.Tablet,
                                value: 65,
                              },
                              justifyContent: {
                                minWidth: Breakpoints.Tablet,
                                value: 'space-between',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Cancel */}
                          <Button
                            onPress={() => {
                              try {
                                setShowCompleteOrderModal(false);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['Button']
                              .props}
                            activeOpacity={0.3}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button']
                                  .style,
                                {
                                  backgroundColor: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'rgba(0, 0, 0, 0)',
                                  },
                                  borderRadius: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 0,
                                  },
                                  color: [
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: theme.colors['Strong'],
                                    },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: theme.colors['Error'],
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: theme.colors['Error'],
                                    },
                                  ],
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'System',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_700Bold',
                                    },
                                  ],
                                  fontSize: [
                                    { minWidth: Breakpoints.Tablet, value: 16 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 17,
                                    },
                                  ],
                                  fontWeight: {
                                    minWidth: Breakpoints.Desktop,
                                    value: '600',
                                  },
                                  height: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 65,
                                  },
                                  width: {
                                    minWidth: Breakpoints.Tablet,
                                    value: '50%',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Cancel'}
                          />
                          {/* Complete */}
                          <Button
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  setIsLoading(true);
                                  const api_Response = (
                                    await photoReadyXanoCompleteOrderPOST.mutateAsync(
                                      { order_id: tempItem?.id }
                                    )
                                  )?.json;
                                  setIsLoading(false);
                                  setShowCompleteOrderModal(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['Button']
                              .props}
                            activeOpacity={0.3}
                            loading={isLoading}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button']
                                  .style,
                                {
                                  backgroundColor: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'rgba(0, 0, 0, 0)',
                                  },
                                  borderColor: {
                                    minWidth: Breakpoints.Desktop,
                                    value: theme.colors['Divider'],
                                  },
                                  borderLeftWidth: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 1,
                                  },
                                  borderRadius: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 0,
                                  },
                                  color: [
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: theme.colors['Error'],
                                    },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: theme.colors['Custom Color_5'],
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: theme.colors['Custom Color_5'],
                                    },
                                  ],
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'System',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'BaiJamjuree_700Bold',
                                    },
                                  ],
                                  fontSize: [
                                    { minWidth: Breakpoints.Tablet, value: 16 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 17,
                                    },
                                  ],
                                  fontWeight: {
                                    minWidth: Breakpoints.Desktop,
                                    value: '600',
                                  },
                                  height: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 65,
                                  },
                                  width: {
                                    minWidth: Breakpoints.Tablet,
                                    value: '50%',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Yes, Complete'}
                          />
                        </View>
                      </View>
                    </View>
                  </Modal>
                </View>
              </>
            );
          }}
        </PhotoReadyXanoApi.FetchOrdersGET>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(OrdersScreen);
