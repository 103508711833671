import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const adminLoginPOST = (Constants, { email, password }, handlers = {}) =>
  fetch(`https://xtju-lwnm-3a3m.n7c.xano.io/api:hH0cDTiA/auth/login`, {
    body: JSON.stringify({ email: email, password: password }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useAdminLoginPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['photoReadyXanoAdminLoginPOST', args],
    () => adminLoginPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['photoReadyXanoAdminLoginPOSTS']),
    }
  );
};

export const FetchAdminLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAdminLoginPOST(
    { email, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAdminLogin: refetch });
};

export const completeOrderPOST = (Constants, { order_id }, handlers = {}) =>
  fetch(`https://xtju-lwnm-3a3m.n7c.xano.io/api:hH0cDTiA/orders/complete`, {
    body: JSON.stringify({ order_id: order_id }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCompleteOrderPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => completeOrderPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Orders', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Order');
        queryClient.invalidateQueries('Orders');
      },
    }
  );
};

export const FetchCompleteOrderPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  order_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCompleteOrderPOST(
    { order_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCompleteOrder: refetch });
};

export const dashboardGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xtju-lwnm-3a3m.n7c.xano.io/api:hH0cDTiA/Dashboard`, {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  }).then(res => handleResponse(res, handlers));

export const useDashboardGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Dashboards', args],
    () => dashboardGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchDashboardGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDashboardGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDashboard: refetch });
};

export const getAllUsersGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xtju-lwnm-3a3m.n7c.xano.io/api:hH0cDTiA/users`, {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  }).then(res => handleResponse(res, handlers));

export const useGetAllUsersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Users', args],
    () => getAllUsersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAllUsersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllUsersGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAllUsers: refetch });
};

export const ordersGET = (Constants, { status }, handlers = {}) =>
  fetch(
    `https://xtju-lwnm-3a3m.n7c.xano.io/api:hH0cDTiA/orders?status=${encodeQueryParam(
      `${typeof status === 'string' ? status : JSON.stringify(status ?? '')}`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useOrdersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Orders', args],
    () => ordersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchOrdersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  status,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useOrdersGET(
    { status },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchOrders: refetch });
};

export const photosSyncGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xtju-lwnm-3a3m.n7c.xano.io/api:hH0cDTiA/photos/sync`, {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  }).then(res => handleResponse(res, handlers));

export const usePhotosSyncGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['photoReadyXanoPhotosSyncGET', args],
    () => photosSyncGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['photoReadyXanoPhotosSyncGETS']),
    }
  );
};

export const FetchPhotosSyncGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePhotosSyncGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPhotosSync: refetch });
};
