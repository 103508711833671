import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PhotoReadyXanoApi from '../apis/PhotoReadyXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Button, ScreenContainer, TextInput, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, ImageBackground, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const LoginScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const inputValidations = () => {
    const expr =
      /^([\w-\.]+)@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/;
    const passwordExpr = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@!$#%]{8,}$/;

    let foundError = false;

    if (username.length < 1) {
      setErrorMessage('Please enter your email');
      foundError = true;
      return foundError;
    } else {
      setErrorMessage('');
    }

    if (!expr.test(username)) {
      setErrorMessage('Please enter a valid email');
      foundError = true;
      return foundError;
    } else {
      setErrorMessage('');
    }

    if (password.length < 1) {
      setErrorMessage('Please enter your password');
      foundError = true;
      return foundError;
    } else {
      setErrorMessage('');
    }

    return foundError;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (Constants['AUTH_TOKEN']?.length) {
        navigation.navigate('DashboardScreen');
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: theme.colors['Theme_Color'],
          },
        },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={Images.Chameleon}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            {
              bottom: [
                { minWidth: Breakpoints.Desktop, value: 0 },
                { minWidth: Breakpoints.Laptop, value: 0 },
                { minWidth: Breakpoints.Mobile, value: 0 },
              ],
              left: [
                { minWidth: Breakpoints.Desktop, value: '50%' },
                { minWidth: Breakpoints.Laptop, value: 0 },
                { minWidth: Breakpoints.Mobile, value: 0 },
              ],
              opacity: [
                { minWidth: Breakpoints.Desktop, value: 0.3 },
                { minWidth: Breakpoints.Laptop, value: 0.15 },
                { minWidth: Breakpoints.Mobile, value: 0.15 },
              ],
              position: [
                { minWidth: Breakpoints.Desktop, value: 'absolute' },
                { minWidth: Breakpoints.Laptop, value: 'absolute' },
                { minWidth: Breakpoints.Mobile, value: 'absolute' },
              ],
              right: [
                { minWidth: Breakpoints.Desktop, value: 0 },
                { minWidth: Breakpoints.Laptop, value: 0 },
                { minWidth: Breakpoints.Mobile, value: 0 },
              ],
              top: [
                { minWidth: Breakpoints.Desktop, value: 0 },
                { minWidth: Breakpoints.Laptop, value: 0 },
                { minWidth: Breakpoints.Mobile, value: 0 },
              ],
            }
          ),
          dimensions.width
        )}
      />
      <KeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        contentContainerStyle={StyleSheet.applyWidth(
          {
            alignItems: [
              { minWidth: Breakpoints.Tablet, value: 'stretch' },
              { minWidth: Breakpoints.Laptop, value: 'center' },
            ],
            flex: { minWidth: Breakpoints.Tablet, value: 1 },
            flexDirection: [
              { minWidth: Breakpoints.Laptop, value: 'column' },
              { minWidth: Breakpoints.Desktop, value: 'column' },
            ],
            justifyContent: [
              { minWidth: Breakpoints.Tablet, value: 'center' },
              { minWidth: Breakpoints.Laptop, value: 'space-between' },
              { minWidth: Breakpoints.Mobile, value: 'space-between' },
            ],
            marginRight: { minWidth: Breakpoints.Desktop, value: '50%' },
          },
          dimensions.width
        )}
        keyboardShouldPersistTaps={'always'}
      >
        {/* Form */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
              flex: { minWidth: Breakpoints.Tablet, value: 1 },
              justifyContent: { minWidth: Breakpoints.Tablet, value: 'center' },
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { paddingBottom: { minWidth: Breakpoints.Tablet, value: '15%' } },
              dimensions.width
            )}
          >
            {/* Heading */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Tablet,
                        value: 'Aclonica_400Regular',
                      },
                      {
                        minWidth: Breakpoints.Desktop,
                        value: 'Aclonica_400Regular',
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'BaiJamjuree_600SemiBold',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Tablet, value: 35 },
                      { minWidth: Breakpoints.Desktop, value: 42 },
                    ],
                    lineHeight: { minWidth: Breakpoints.Desktop, value: 55 },
                    marginBottom: { minWidth: Breakpoints.Desktop, value: 35 },
                    marginLeft: { minWidth: Breakpoints.Desktop, value: null },
                  }
                ),
                dimensions.width
              )}
            >
              {'Welcome back to \n'}
              {Constants['PROJECT_NAME']}
            </Text>
            {/* Username */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newUsernameValue => {
                const textInputValue = newUsernameValue;
                try {
                  setUsername(newUsernameValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              placeholder={'Enter your email'}
              placeholderTextColor={theme.colors['Light']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    backgroundColor: {
                      minWidth: Breakpoints.Tablet,
                      value: theme.colors['Custom Color_2'],
                    },
                    borderColor: {
                      minWidth: Breakpoints.Tablet,
                      value: theme.colors['Light'],
                    },
                    borderRadius: { minWidth: Breakpoints.Tablet, value: 12 },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Tablet,
                        value: 'Poppins_400Regular',
                      },
                      {
                        minWidth: Breakpoints.Desktop,
                        value: 'BaiJamjuree_400Regular',
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'BaiJamjuree_400Regular',
                      },
                    ],
                    fontSize: { minWidth: Breakpoints.Tablet, value: 17 },
                    height: { minWidth: Breakpoints.Tablet, value: 60 },
                    marginTop: { minWidth: Breakpoints.Tablet, value: 20 },
                    paddingLeft: { minWidth: Breakpoints.Tablet, value: 16 },
                  }
                ),
                dimensions.width
              )}
              value={username}
            />
            {/* Password */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newPasswordValue => {
                try {
                  setPassword(newPasswordValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              placeholder={'Enter your Password'}
              placeholderTextColor={theme.colors['Light']}
              secureTextEntry={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    backgroundColor: {
                      minWidth: Breakpoints.Tablet,
                      value: theme.colors['Custom Color_2'],
                    },
                    borderColor: {
                      minWidth: Breakpoints.Tablet,
                      value: theme.colors['Light'],
                    },
                    borderRadius: { minWidth: Breakpoints.Tablet, value: 12 },
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Tablet,
                        value: 'Poppins_400Regular',
                      },
                      {
                        minWidth: Breakpoints.Desktop,
                        value: 'BaiJamjuree_400Regular',
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'BaiJamjuree_400Regular',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Tablet, value: 17 },
                      { minWidth: Breakpoints.Desktop, value: 18 },
                    ],
                    height: { minWidth: Breakpoints.Tablet, value: 60 },
                    marginTop: { minWidth: Breakpoints.Tablet, value: 20 },
                    paddingLeft: { minWidth: Breakpoints.Tablet, value: 16 },
                  }
                ),
                dimensions.width
              )}
              value={password}
            />
            {/* error message */}
            <>
              {!errorMessage ? null : (
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: {
                          minWidth: Breakpoints.Tablet,
                          value: theme.colors['Error'],
                        },
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Tablet,
                            value: 'Poppins_400Regular',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'BaiJamjuree_400Regular',
                          },
                        ],
                        margin: { minWidth: Breakpoints.Tablet, value: 20 },
                        marginBottom: {
                          minWidth: Breakpoints.Tablet,
                          value: 0,
                        },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {errorMessage}
                </Text>
              )}
            </>
            {/* Login */}
            <Button
              onPress={() => {
                const handler = async () => {
                  try {
                    const foundError = inputValidations();
                    if (foundError) {
                      return;
                    }
                    setIsLoading(true);
                    const api_Response = (
                      await PhotoReadyXanoApi.adminLoginPOST(Constants, {
                        email: username,
                        password: password,
                      })
                    )?.json;
                    setErrorMessage(api_Response?.message);
                    setIsLoading(false);
                    if (api_Response?.authToken?.length) {
                      setGlobalVariableValue({
                        key: 'AUTH_TOKEN',
                        value: 'Bearer ' + api_Response?.authToken,
                      });
                    }
                    if (api_Response?.authToken?.length) {
                      navigation.navigate('DashboardScreen');
                    }
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              loading={isLoading}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Tablet,
                        value: theme.colors['Primary'],
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Primary'],
                      },
                    ],
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Tablet,
                        value: 'Poppins_700Bold',
                      },
                      {
                        minWidth: Breakpoints.Desktop,
                        value: 'Poppins_500Medium',
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'BaiJamjuree_700Bold',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Tablet, value: 18 },
                      { minWidth: Breakpoints.Desktop, value: 20 },
                    ],
                    height: { minWidth: Breakpoints.Desktop, value: 55 },
                    marginTop: { minWidth: Breakpoints.Tablet, value: 35 },
                  }
                ),
                dimensions.width
              )}
              title={' Login'}
            />
          </View>
        </View>
        {/*  Bottom View */}
        <View
          style={StyleSheet.applyWidth(
            {
              flex: { minWidth: Breakpoints.Tablet, value: 0 },
              justifyContent: {
                minWidth: Breakpoints.Tablet,
                value: 'flex-end',
              },
              paddingBottom: { minWidth: Breakpoints.Tablet, value: 10 },
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: { minWidth: Breakpoints.Tablet, value: 'center' } },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Tablet,
                        value: 'Poppins_400Regular',
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'BaiJamjuree_400Regular',
                      },
                    ],
                    fontSize: { minWidth: Breakpoints.Tablet, value: 11 },
                    lineHeight: { minWidth: Breakpoints.Tablet, value: 14 },
                  }
                ),
                dimensions.width
              )}
            >
              {'Powered by:'}
            </Text>
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={Images.DraftbitLogo}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  { height: { minWidth: Breakpoints.Tablet, value: 35 } }
                ),
                dimensions.width
              )}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(LoginScreen);
