import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, Modal, Text, View } from 'react-native';

const ComponentHeaderScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [showLogoutPopUp, setShowLogoutPopUp] = React.useState(false);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* HeaderBlock */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
            backgroundColor: {
              minWidth: Breakpoints.Laptop,
              value: theme.colors['Theme_Color'],
            },
            flexDirection: { minWidth: Breakpoints.Tablet, value: 'row' },
            height: { minWidth: Breakpoints.Tablet, value: 65 },
            justifyContent: {
              minWidth: Breakpoints.Tablet,
              value: 'space-between',
            },
            paddingLeft: { minWidth: Breakpoints.Tablet, value: 20 },
            paddingRight: { minWidth: Breakpoints.Tablet, value: 20 },
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: [
                { minWidth: Breakpoints.Desktop, value: 'center' },
                { minWidth: Breakpoints.Laptop, value: 'center' },
              ],
              flexDirection: [
                { minWidth: Breakpoints.Desktop, value: 'row' },
                { minWidth: Breakpoints.Laptop, value: 'row' },
              ],
            },
            dimensions.width
          )}
        >
          <Image
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            resizeMode={'contain'}
            source={Images.Chameleon}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                {
                  height: { minWidth: Breakpoints.Tablet, value: 55 },
                  width: [
                    { minWidth: Breakpoints.Desktop, value: 55 },
                    { minWidth: Breakpoints.Laptop, value: 55 },
                  ],
                }
              ),
              dimensions.width
            )}
          />
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: [
                  {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Custom Color_2'],
                  },
                  {
                    minWidth: Breakpoints.BigScreen,
                    value: theme.colors['Custom Color_5'],
                  },
                ],
                fontFamily: {
                  minWidth: Breakpoints.Laptop,
                  value: 'BaiJamjuree_600SemiBold',
                },
                fontSize: { minWidth: Breakpoints.Laptop, value: 30 },
              }),
              dimensions.width
            )}
          >
            {'PhotoReady App'}
          </Text>
        </View>
        {/* Logout */}
        <Button
          onPress={() => {
            try {
              setShowLogoutPopUp(true);
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          icon={'AntDesign/logout'}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              {
                backgroundColor: {
                  minWidth: Breakpoints.Tablet,
                  value: theme.colors['Custom Color_2'],
                },
                color: {
                  minWidth: Breakpoints.Tablet,
                  value: theme.colors['Error'],
                },
                fontFamily: [
                  { minWidth: Breakpoints.Tablet, value: 'Poppins_400Regular' },
                  {
                    minWidth: Breakpoints.Desktop,
                    value: 'BaiJamjuree_400Regular',
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: 'BaiJamjuree_400Regular',
                  },
                ],
                fontSize: [
                  { minWidth: Breakpoints.Tablet, value: 15 },
                  { minWidth: Breakpoints.Desktop, value: 17 },
                ],
                letterSpacing: { minWidth: Breakpoints.Desktop, value: 1.1 },
              }
            ),
            dimensions.width
          )}
          title={'  Log Out'}
        />
      </View>
      {/* Log Out Pop Up */}
      <Modal
        animationType={'none'}
        transparent={true}
        visible={showLogoutPopUp}
      >
        {/* BG */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Tablet,
                value: theme.colors['Strong'],
              },
              bottom: { minWidth: Breakpoints.Tablet, value: 0 },
              left: { minWidth: Breakpoints.Tablet, value: 0 },
              opacity: { minWidth: Breakpoints.Tablet, value: 0.3 },
              position: { minWidth: Breakpoints.Tablet, value: 'absolute' },
              right: { minWidth: Breakpoints.Tablet, value: 0 },
              top: { minWidth: Breakpoints.Tablet, value: 0 },
            },
            dimensions.width
          )}
        />
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
              flex: { minWidth: Breakpoints.Tablet, value: 1 },
              justifyContent: { minWidth: Breakpoints.Tablet, value: 'center' },
            },
            dimensions.width
          )}
        >
          {/* Pop up */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: {
                  minWidth: Breakpoints.Tablet,
                  value: theme.colors['Custom Color_2'],
                },
                borderRadius: [
                  { minWidth: Breakpoints.Tablet, value: 20 },
                  { minWidth: Breakpoints.Desktop, value: 10 },
                  { minWidth: Breakpoints.Laptop, value: 8 },
                ],
                height: { minWidth: Breakpoints.Tablet, value: 220 },
                justifyContent: {
                  minWidth: Breakpoints.Tablet,
                  value: 'space-between',
                },
                overflow: { minWidth: Breakpoints.Tablet, value: 'hidden' },
                width: { minWidth: Breakpoints.Tablet, value: 350 },
              },
              dimensions.width
            )}
          >
            {/* Title */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Desktop,
                        value: 'BaiJamjuree_500Medium',
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: 'BaiJamjuree_500Medium',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Tablet, value: 21 },
                      { minWidth: Breakpoints.Desktop, value: 22 },
                    ],
                    padding: { minWidth: Breakpoints.Tablet, value: 9 },
                  }
                ),
                dimensions.width
              )}
            >
              {'Logout!'}
            </Text>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: {
                    minWidth: Breakpoints.Tablet,
                    value: theme.colors['Divider'],
                  },
                  borderTopWidth: { minWidth: Breakpoints.Tablet, value: 1 },
                  justifyContent: {
                    minWidth: Breakpoints.Tablet,
                    value: 'center',
                  },
                },
                dimensions.width
              )}
            >
              {/* Description */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                numberOfLines={2}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Tablet,
                          value: 'Poppins_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Desktop,
                          value: 'BaiJamjuree_300Light',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'BaiJamjuree_400Regular',
                        },
                      ],
                      fontSize: { minWidth: Breakpoints.Desktop, value: 18 },
                      lineHeight: { minWidth: Breakpoints.Tablet, value: 26 },
                      padding: { minWidth: Breakpoints.Tablet, value: 20 },
                      paddingBottom: {
                        minWidth: Breakpoints.Tablet,
                        value: 12,
                      },
                      paddingTop: { minWidth: Breakpoints.Tablet, value: 12 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Are you sure to Log out from Admin Panel?'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.Tablet, value: 'center' },
                    { minWidth: Breakpoints.Desktop, value: 'stretch' },
                    { minWidth: Breakpoints.Laptop, value: 'stretch' },
                  ],
                  borderColor: {
                    minWidth: Breakpoints.Tablet,
                    value: theme.colors['Divider'],
                  },
                  borderWidth: { minWidth: Breakpoints.Tablet, value: 1 },
                  flexDirection: { minWidth: Breakpoints.Tablet, value: 'row' },
                  height: { minWidth: Breakpoints.Tablet, value: 65 },
                  justifyContent: {
                    minWidth: Breakpoints.Tablet,
                    value: 'space-between',
                  },
                },
                dimensions.width
              )}
            >
              {/* Cancel */}
              <Button
                onPress={() => {
                  try {
                    setShowLogoutPopUp(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                activeOpacity={0.3}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Tablet,
                        value: 'rgba(0, 0, 0, 0)',
                      },
                      borderRadius: [
                        { minWidth: Breakpoints.Desktop, value: 0 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                      color: {
                        minWidth: Breakpoints.Tablet,
                        value: theme.colors['Strong'],
                      },
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Desktop,
                          value: 'BaiJamjuree_600SemiBold',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'BaiJamjuree_700Bold',
                        },
                      ],
                      fontSize: [
                        { minWidth: Breakpoints.Tablet, value: 16 },
                        { minWidth: Breakpoints.Desktop, value: 18 },
                      ],
                      width: { minWidth: Breakpoints.Tablet, value: '50%' },
                    }
                  ),
                  dimensions.width
                )}
                title={'Cancel'}
              />
              {/* LOGOUT */}
              <Button
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'AUTH_TOKEN',
                      value: '',
                    });
                    navigation.navigate('LoginScreen');
                    setShowLogoutPopUp(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                activeOpacity={0.3}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Tablet,
                        value: 'rgba(0, 0, 0, 0)',
                      },
                      borderColor: [
                        {
                          minWidth: Breakpoints.Desktop,
                          value: theme.colors['Divider'],
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Divider'],
                        },
                      ],
                      borderLeftWidth: [
                        { minWidth: Breakpoints.Desktop, value: 1 },
                        { minWidth: Breakpoints.Laptop, value: 1 },
                      ],
                      borderRadius: [
                        { minWidth: Breakpoints.Desktop, value: 0 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                      color: {
                        minWidth: Breakpoints.Tablet,
                        value: theme.colors['Error'],
                      },
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Desktop,
                          value: 'BaiJamjuree_600SemiBold',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'BaiJamjuree_700Bold',
                        },
                      ],
                      fontSize: [
                        { minWidth: Breakpoints.Tablet, value: 16 },
                        { minWidth: Breakpoints.Desktop, value: 18 },
                      ],
                      width: { minWidth: Breakpoints.Tablet, value: '50%' },
                    }
                  ),
                  dimensions.width
                )}
                title={'LOGOUT'}
              />
            </View>
          </View>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(ComponentHeaderScreen);
