import React from 'react';

import ComponentHeader from '../screens/ComponentHeaderScreen';

export const MyHeader = props => {
  const route = {
    params: props,
  };

  return <ComponentHeader route={route} {...props} />;
};
