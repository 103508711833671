import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

export const ImageBackgroundStyles = theme =>
  StyleSheet.create({ 'Image Background': { style: { flex: 1 }, props: {} } });

export const SurfaceStyles = theme =>
  StyleSheet.create({
    Dashboard_Card: {
      style: {
        borderColor: {
          minWidth: Breakpoints.Tablet,
          value: theme.colors['Divider'],
        },
        borderRadius: { minWidth: Breakpoints.Tablet, value: 12 },
        borderTopWidth: { minWidth: Breakpoints.Tablet, value: 1 },
        borderWidth: { minWidth: Breakpoints.Tablet, value: 1 },
        height: [
          { minWidth: Breakpoints.Tablet, value: 140 },
          { minWidth: Breakpoints.Desktop, value: 200 },
        ],
        marginBottom: { minWidth: Breakpoints.Tablet, value: '5%' },
        marginRight: { minWidth: Breakpoints.Tablet, value: '5%' },
        minHeight: 40,
        overflow: { minWidth: Breakpoints.Tablet, value: 'hidden' },
        width: [
          { minWidth: Breakpoints.Tablet, value: 140 },
          { minWidth: Breakpoints.Desktop, value: 200 },
        ],
      },
      props: {},
    },
    'Menu Surface': {
      style: {
        height: { minWidth: Breakpoints.Tablet, value: 65 },
        justifyContent: { minWidth: Breakpoints.Tablet, value: 'center' },
        minHeight: [
          { minWidth: Breakpoints.Mobile, value: 40 },
          { minWidth: Breakpoints.Tablet, value: 60 },
        ],
      },
      props: {},
    },
    Surface: { style: { minHeight: 40 }, props: {} },
  });

export const TextStyles = theme =>
  StyleSheet.create({
    Dashboard_Card_Count: {
      style: {
        color: [
          { minWidth: Breakpoints.Mobile, value: theme.colors.strong },
          { minWidth: Breakpoints.Desktop, value: theme.colors['Error'] },
        ],
        fontFamily: {
          minWidth: Breakpoints.Tablet,
          value: 'Comfortaa_700Bold',
        },
        fontSize: [
          { minWidth: Breakpoints.Tablet, value: 40 },
          { minWidth: Breakpoints.Desktop, value: 50 },
        ],
        textAlign: { minWidth: Breakpoints.Tablet, value: 'center' },
      },
      props: {},
    },
    Dashboard_Card_Label: {
      style: {
        color: theme.colors.strong,
        fontFamily: {
          minWidth: Breakpoints.Tablet,
          value: 'Poppins_400Regular',
        },
        fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
        textAlign: { minWidth: Breakpoints.Tablet, value: 'center' },
      },
      props: {},
    },
    List_Labels: {
      style: {
        color: theme.colors.strong,
        fontFamily: {
          minWidth: Breakpoints.Tablet,
          value: 'Poppins_500Medium',
        },
        fontSize: [
          { minWidth: Breakpoints.Tablet, value: 15 },
          { minWidth: Breakpoints.Desktop, value: 16 },
        ],
        marginLeft: { minWidth: Breakpoints.Tablet, value: 10 },
        textAlign: { minWidth: Breakpoints.Tablet, value: 'center' },
      },
      props: {},
    },
    List_Records_Texts: {
      style: {
        color: theme.colors.strong,
        fontFamily: [
          { minWidth: Breakpoints.Tablet, value: 'Poppins_400Regular' },
          { minWidth: Breakpoints.Desktop, value: 'Poppins_300Light' },
        ],
        fontSize: [
          { minWidth: Breakpoints.Tablet, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 15 },
        ],
        marginLeft: { minWidth: Breakpoints.Tablet, value: 10 },
      },
      props: {},
    },
    Text: {
      style: {
        color: theme.colors.strong,
        fontFamily: {
          minWidth: Breakpoints.Tablet,
          value: 'Poppins_500Medium',
        },
        fontSize: { minWidth: Breakpoints.Tablet, value: 16 },
        marginLeft: { minWidth: Breakpoints.Tablet, value: 10 },
        textAlign: { minWidth: Breakpoints.Tablet, value: 'center' },
      },
      props: {},
    },
  });

export const ImageStyles = theme =>
  StyleSheet.create({
    Image: { style: { height: 100, width: 100 }, props: {} },
  });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { style: { minHeight: 40 }, props: {} } });

export const ButtonStyles = theme =>
  StyleSheet.create({
    Button: {
      style: {
        borderRadius: 8,
        fontFamily: 'System',
        fontWeight: '700',
        textAlign: 'center',
      },
      props: {},
    },
  });

export const TextInputStyles = theme =>
  StyleSheet.create({
    'Text Input': {
      style: {
        borderBottomWidth: 1,
        borderColor: theme.colors.divider,
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
  });

export const ActivityIndicatorStyles = theme =>
  StyleSheet.create({
    'Activity Indicator': { style: { height: 36, width: 36 }, props: {} },
  });
